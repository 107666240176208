import React from 'react';
import { Row, Col } from 'reactstrap';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { MainLayout } from '../components/Layout';

const EmailVerification = ({ match }) => {
  const [verifiedMessage, setVerifiedMessage] = useState();
  const { confirmationToken } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/email-verification/${confirmationToken}`,
      )
      .then(response => {
        setVerifiedMessage(response?.data?.message);
        setTimeout(() => navigate('/'), 3000);
      });
  }, [confirmationToken, navigate]);
  return (
    <MainLayout>
      <div className="sm-center" style={{ flex: 4, placeItems: 'center' }}>
        <Row>
          <Col md={4}></Col>
          <Col md={4} className="mx-3">
            {/* <h3>Email Verified Successfully</h3> */}
            <h3>{verifiedMessage} -</h3>
          </Col>
        </Row>
      </div>
    </MainLayout>
  );
};

export default EmailVerification;
