import { UserCard } from '../Card';
import SearchInput from '../SearchInput';
import React, { useState } from 'react';
import { MdClearAll, MdExitToApp, MdPersonPin } from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  // NavbarToggler,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';
import bn from '../../utils/bemnames';
import { removeStorage } from '../../helper/localStorage';
import { Link, useNavigate } from 'react-router-dom';
import authService from '../../services/auth.service';
import { useEffect } from 'react';

const bem = bn.create('header');

const Header = () => {
  const [showSearch, setShowSearch] = useState(true);
  const path = window.location.pathname;
  const navigate = useNavigate();

  const [isOpenUserCardPopover, setIsOpenUserCardPopover] = useState(false);

  const toggleUserCardPopover = () => {
    setIsOpenUserCardPopover(!isOpenUserCardPopover);
  };

  const handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };
  const logout = () => {
    removeStorage('user');
    navigate('/login');
  };

  useEffect(() => {
    if (path === '/profile') {
      setShowSearch(false);
    }
  }, [path]);

  return (
    <Navbar light expand className={bem.b('bg-white')}>
      <Nav navbar className="mr-2">
        <Button outline onClick={handleSidebarControlButton}>
          <MdClearAll size={25} />
        </Button>
      </Nav>
      <Nav navbar>{showSearch && <SearchInput />}</Nav>

      <Nav navbar className={bem.e('nav-right')}>
        <NavItem className="d-inline-flex">
          <NavLink id="Popover1" className="position-relative"></NavLink>
        </NavItem>

        <NavItem>
          <NavLink id="Popover2">
            <div
              onClick={toggleUserCardPopover}
              className="can-click"
              style={{
                fontSize: '1.4em',
                border: '3px solid gray',
                // fontSize: '20px',
                fontWeight: '500',
                borderRadius: '100%',
                width: '42px',
                height: '42px',
                display: 'grid',
                placeItems: 'center',
              }}
            >
              {authService.getCurrentUser()?.name[0]}
            </div>
          </NavLink>
          <Popover
            placement="bottom-end"
            isOpen={isOpenUserCardPopover}
            toggle={toggleUserCardPopover}
            target="Popover2"
            className="p-0 border-0"
            style={{ minWidth: 250 }}
          >
            <PopoverBody className="p-0 border-light">
              <UserCard
                title={authService.getCurrentUser()?.name}
                subtitle={authService.getCurrentUser()?.email}
                className="border-light"
              >
                <ListGroup flush>
                  <ListGroupItem tag="button" action className="border-light">
                    <Link to="/profile">
                      <MdPersonPin /> Profile
                    </Link>
                  </ListGroupItem>

                  <ListGroupItem
                    tag="button"
                    onClick={logout}
                    action
                    className="border-light"
                  >
                    <MdExitToApp /> Signout
                  </ListGroupItem>
                </ListGroup>
              </UserCard>
            </PopoverBody>
          </Popover>
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default Header;
