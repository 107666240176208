import axios from 'axios';
import { getStorage } from '../../helper/localStorage';
import { API_ENDPOINT } from '../../utils/constants';

export const getDealerList = async () => {
  var users = getStorage('user');
  if (users) {
    users = JSON.parse(users);
    const options = {
      url: `${API_ENDPOINT}/dealer-dashboard/getDealerList`,
      method: 'GET',
      headers: {
        'x-access-token': users.accessToken,
      },
    };
    const response = await axios(options).catch(err => {
      console.log(err.response);
    });
    return response.data;
  } else {
    return [];
  }
};

export const getLeadByDealerData = async (
  dealerId,
  selectAll = false,
  keyword,
) => {
  var users = getStorage('user');
  if (users) {
    users = JSON.parse(users);
    const options = {
      url: `${API_ENDPOINT}/dealer-dashboard/getLeadByDealer`,
      method: 'POST',
      data: {
        dealerId: dealerId,
        ...(selectAll && { selectAll: true }),
        ...(keyword && { keyword: keyword }),
      },
      headers: {
        'x-access-token': users.accessToken,
      },
    };
    const response = await axios(options).catch(err => {
      console.log(err.response);
    });
    return response.data;
  } else {
    return [];
  }
};
export const addLeadToDealer = async (dealerId, leadIds) => {
  var users = getStorage('user');
  if (users) {
    users = JSON.parse(users);
    const options = {
      url: `${API_ENDPOINT}/dealer-dashboard/addLeadForDealer`,
      method: 'POST',
      data: { dealerId: dealerId, leadIds: leadIds },
      headers: {
        'x-access-token': users.accessToken,
      },
    };
    const response = await axios(options).catch(err => {
      console.log(err.response);
    });
    return response.data;
  } else {
    return [];
  }
};
