import React, { useState } from 'react';
import { MdSearch } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { Form, Input } from 'reactstrap';
import { getLeadData } from '../apis/pages/Lead';
import { setSearchValue } from '../redux/actions/searchAction';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';

const SearchInput = () => {
  const navigate = useNavigate();
  const [, setSearchText] = useState([]);
  const dispatch = useDispatch();
  const path = window.location.pathname;

  const addSearch = async (query, cb) => {
    setSearchText(query);
    const response = await getLeadData({ keyword: query });
    response.keyword = query;
    dispatch(setSearchValue(response));
    if (path !== '/leads') {
      navigate({ path: '/' });
    }
  };

  const debounceAddSearch = debounce((query, cb) => {
    addSearch(query, cb);
  }, 500);
  return (
    <Form inline className="cr-search-form" onSubmit={e => e.preventDefault()}>
      <MdSearch
        size="20"
        className="cr-search-form__icon-search text-secondary"
      />
      <Input
        type="search"
        className="cr-search-form__input"
        placeholder="Search..."
        onChange={e => {
          debounceAddSearch(e.target.value);
        }}
      />
    </Form>
  );
};

export default SearchInput;
