import axios from 'axios';
import { getStorage } from '../../helper/localStorage';
import { API_ENDPOINT } from '../../utils/constants';

export const getLeadData = async data => {
  var users = getStorage('user');
  if (users) {
    users = JSON.parse(users);
    const options = {
      url: `${API_ENDPOINT}/dealer-dashboard/leads`,
      method: 'POST',
      data: data,
      headers: {
        'x-access-token': users.accessToken,
      },
    };
    const response = await axios(options).catch(err => {
      console.log(err.response);
    });
    return response.data;
  } else {
    return [];
  }
};
