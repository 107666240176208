/* Encode string to slug */
export const convertToSlug = str => {
  str = str
    .replace(/[`~!@#$%^&*()_\-+=[\]{};:'"\\|\\/,.<>?\s]/g, ' ')
    .toLowerCase();

  // trim spaces at start and end of string
  str = str.replace(/^\s+|\s+$/gm, '');

  // replace space with dash/hyphen
  str = str.replace(/\s+/g, '-');
  return str;
};
