import * as actions from '../constants/action-types';

const searchInitialState = {};

export const searchReducer = async (
  state = searchInitialState,
  { type, payload },
) => {
  switch (type) {
    case actions.SEARCH_PRODUCTS:
      return { leads: payload };
    default:
      return state;
  }
};
