import sidebarBgImage from '../../assets/img/sidebar/sidebar-4.jpg';
import SourceLink from '../SourceLink';
import React from 'react';
import { MdPersonPin, MdPerson, MdStar } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import {
  // UncontrolledTooltip,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from '../../utils/bemnames';
import { getUseProfileData } from '../../apis/pages/user';

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenComponents: true,
      isOpenContents: true,
      isOpenPages: true,
      isAdmin: false,
      navContents: [],
    };
  }

  componentDidMount() {
    getUseProfileData().then(user => {
      user.data.role === 'Admin'
        ? this.setState({ isAdmin: true })
        : this.setState({ isAdmin: false });
      this.setState({
        navContents: [
          { to: '/', name: 'User', exact: false, Icon: MdPerson },
          {
            to: '/profile',
            name: 'Profile',
            exact: false,
            Icon: MdPersonPin,
          },
        ],
      });

      if (this.state.isAdmin) {
        this.setState({
          navContents: [
            { to: '/', name: 'User', exact: false, Icon: MdPerson },
            {
              to: '/profile',
              name: 'Profile',
              exact: false,
              Icon: MdPersonPin,
            },
            {
              to: '/leads',
              name: 'Leads',
              exact: false,
              Icon: MdStar,
            },
          ],
        });
      }
      this.setState({ isAdmin: true });
    });
  }
  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar>
            <SourceLink className="navbar-brand d-flex">
              <img
                src="https://d3g7ucapfhrqqt.cloudfront.net/images/ironlist-logo-2.webp"
                width="180"
                height="30"
                className="pr-2"
                alt=""
              />
            </SourceLink>
          </Navbar>
          <Nav vertical>
            <Collapse isOpen={this.state.isOpenComponents}></Collapse>

            <Collapse isOpen={this.state.isOpenContents}>
              {this.state.navContents?.map(
                ({ to, name, exact, Icon }, index) => (
                  <NavItem key={index} className={bem.e('nav-item')}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className="text-uppercase"
                      tag={NavLink}
                      to={to}
                      activeClassName="active"
                      exact={exact}
                    >
                      <Icon className={bem.e('nav-item-icon')} />
                      <span className="">{name}</span>
                    </BSNavLink>
                  </NavItem>
                ),
              )}
            </Collapse>

            <Collapse isOpen={this.state.isOpenPages}></Collapse>
          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
