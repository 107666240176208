import axios from 'axios';
import { API_ENDPOINT } from '../../utils/constants';

export const registerUser = async body => {
  const response = await axios
    .post(`${API_ENDPOINT}/registration`, body)
    .catch(err => {
      console.log(err.response);
    });
  return response.data;
};
export const loginUser = async body => {
  const response = await axios.post(`${API_ENDPOINT}/login`, body);
  return response.data;
};

export const resendEmailVerification = async body => {
  const options = {
    url: `${API_ENDPOINT}/resendEmailVerification`,
    method: 'POST',
    data: body,
  };
  const response = await axios(options);
  return response.data;
};
