import React, { useEffect, useState } from 'react';
// import Page from 'components/Page';
import { Card, CardBody, Col, Input, Row } from 'reactstrap';
import { MdPersonPin } from 'react-icons/md';
import AdminLeadTable from '../components/AdminLeadTable';
import { MainLayout } from '../components/Layout';
import { getDealerList, getLeadByDealerData } from '../apis/pages/admin';
import { getLeadData } from '../apis/pages/Lead';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Dropdown from '../components/shared/DropDown';

const AdminPage = () => {
  const [users, setUsers] = useState([]);
  const [dealer, setDealer] = useState({});
  const [leadDealerList, setleadDealerList] = useState([]);
  const [dealerId, setDealerId] = useState('');
  const [pageCount, setpageCount] = useState(0);
  const [allChecked, setAllChecked] = useState(true);
  const limit = 10;
  const searchListState = useSelector(state => state.searchReducer);
  const navigate = useNavigate();
  const [dealerNameDropdown, setDealerNameDropdown] = useState('Dropdown');

  const handleChange = async (id, check = false) => {
    if (check) {
      setAllChecked(!allChecked);
      if (!allChecked) {
        setleadDealerList([]);
      } else {
        var searchList = await searchListState;
        const listDropdown = await getLeadByDealerData(
          id,
          true,
          searchList?.leads?.keyword,
        );
        setleadDealerList(listDropdown.leads.items);
      }
    } else {
      const selectedDealer = dealer.filter(item => item.id === id);
      setDealerId(id);
      const listDropdown = await getLeadByDealerData(id);
      setleadDealerList(listDropdown.leads.items);
      setDealerNameDropdown(selectedDealer[0].firstname);
    }
  };

  const onCheckBoxChange = async id => {
    if (!leadDealerList.includes(id)) {
      setleadDealerList([...leadDealerList, id]);
    } else {
      var removeIndex = leadDealerList.indexOf(id);
      var tempValue = leadDealerList;
      tempValue.splice(removeIndex, 1);
      setleadDealerList([]);
      setleadDealerList([...leadDealerList]);
    }
  };
  useEffect(() => {
    async function getLeads() {
      var searchList = await searchListState;

      if (searchList?.leads?.leads?.items?.length === undefined) {
        const leadResponse = await getLeadData();
        setpageCount(Math.ceil(leadResponse?.leads?.count / limit));
        setUsers(leadResponse.leads.items);
      } else {
        setpageCount(Math.ceil(searchList?.leads?.leads?.count / limit));
        setUsers(searchList?.leads?.leads?.items);
      }
    }
    getDealerLists();
    getLeads();
  }, [searchListState]);

  const handlePageClick = async data => {
    var searchList = await searchListState;
    navigate({ path: '/', search: `?page=${data.selected + 1}` });

    // setSelectedIndex(data.selected);
    let currentPage = data.selected + 1;
    var body = {
      keyword: searchList?.leads?.keyword,
      page: currentPage,
      sortBy: searchList?.leads?.sortBy,
    };
    const commentsFormServer = await getLeadData(body);
    setUsers(commentsFormServer?.leads?.items);
  };

  async function getDealerLists() {
    const response = await getDealerList();
    setDealer(response.users);
  }

  return (
    <MainLayout>
      <Dropdown
        dealer={dealer}
        handleChange={handleChange}
        dropDownKey={'firstname'}
        dropDownName={dealerNameDropdown}
      />
      <Row>
        <Col md={12} sm={6} xs={12} className="mb-3">
          <Card>
            <CardBody>
              <AdminLeadTable
                headers={[
                  <Input
                    style={{ position: 'inherit', margin: 'auto' }}
                    type="checkbox"
                    name="name"
                    checked={!allChecked}
                    onChange={() => {
                      handleChange(dealerId, true);
                    }}
                  />,
                  <MdPersonPin size={25} />,
                  'product',
                  'name',
                  'email',
                  'number',
                  'city',
                  'state',
                  'country',
                ]}
                usersData={users}
                onCheckBoxChange={onCheckBoxChange}
                handlePageClick={handlePageClick}
                pageCount={pageCount}
                dealer={dealer}
                leadDealerList={leadDealerList}
                dealerId={dealerId}
                // handleChecked={handleChecked}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </MainLayout>
  );
};

export default AdminPage;
