import React, { useState } from 'react';
import PropTypes from '../utils/propTypes';
import { MdRemoveRedEye } from 'react-icons/md';
import { Table, Modal, ModalHeader } from 'reactstrap';
import Avatar from '../components/Avatar';
import ModalPage from '../page/ModalPage';
import ButtonMailto from './shared/ButtonMailto';
import { FaSort } from 'react-icons/fa';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchValue } from '../redux/actions/searchAction';
import { getLeadData } from '../apis/pages/Lead';
import Pagination from './Pagination';
import { convertToSlug } from '../apis/pages/helper';

const UserLeadTable = ({
  headers,
  handlePageClick,
  pageCount,
  usersData,
  selectedIndex,
  ...restProps
}) => {
  const [modal, setModal] = useState(false);
  const [lead, setLead] = useState([{}]);
  const [dateOrderAsc, setDateOrderAsc] = useState(false);
  const dispatch = useDispatch();
  const searchListState = useSelector(state => state.searchReducer);
  const toggle = () => {
    setModal(!modal);
  };
  const urlParams = new URLSearchParams(window.location.search);
  const page = urlParams.get('page');
  const openLead = x => {
    setModal(!modal);
    setLead(x);
  };

  const dateOrderHandle = async () => {
    setDateOrderAsc(!dateOrderAsc);
    var leadDetails = await searchListState;
    const response = await getLeadData({
      sortBy: dateOrderAsc ? 'date_asc' : 'date_desc',
      keyword: leadDetails?.leads?.keyword,
      page: page || 1,
    });
    response.keyword = leadDetails?.leads?.keyword;
    response.sortBy = dateOrderAsc ? 'date_asc' : 'date_desc';
    dispatch(setSearchValue(response));
  };
  return (
    <>
      <Table responsive hover {...restProps}>
        <thead>
          <tr className="text-capitalize align-middle text-center">
            {headers.map((item, index) => (
              <th key={index}>
                {item === 'Date' ? (
                  <>
                    {item}
                    <FaSort onClick={() => dateOrderHandle()}></FaSort>
                  </>
                ) : (
                  item
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {usersData?.length > 0 ? (
            usersData?.map((x, index) => (
              <tr key={index}>
                <td className="align-middle text-center">
                  {selectedIndex >= 1
                    ? selectedIndex * 8 + index + 1
                    : index + 1}
                </td>
                <td className="align-middle text-center">
                  {x.thumbnail_url !== '/thumbnailimagenotfound.jpg' ? (
                    <Avatar src={x.thumbnail_url} />
                  ) : (
                    <Avatar src="https://s3.amazonaws.com/equipment.ceg/noimage2.png" />
                  )}
                </td>
                <td className="align-middle text-center">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      'https://www.ironlist.com/share/' +
                      convertToSlug(x.item_title) +
                      '/' +
                      x.item_id
                    }
                  >
                    {x.item_title}
                  </a>
                </td>

                <td className="align-middle text-center">{x.name}</td>

                <td className="align-middle text-center">
                  <ButtonMailto mailto={'mailto:' + x.email} label={x.email} />
                </td>
                <td className="align-middle text-center">+{x.buyer_phone}</td>
                <td className="align-middle text-center">{x.buyer_city}</td>
                <td className="align-middle text-center">{x.buyer_state}</td>
                <td className="align-middle text-center">{x.buyer_country}</td>
                <td className="align-middle text-center">
                  {/* {x.created_at} */}
                  {new Date(x.created_at).toISOString().substring(0, 10)}{' '}
                  <br></br>
                  {moment(x.created_at).fromNow()}
                </td>
                <td className="align-middle text-center">
                  <MdRemoveRedEye
                    style={{ color: 'blue' }}
                    onClick={() => openLead(x)}
                  />
                </td>
              </tr>
            ))
          ) : (
            <>
              <p>No Result Found</p>
            </>
          )}
        </tbody>
      </Table>

      <Pagination
        pageCount={pageCount}
        handlePageClick={handlePageClick}
        page={page}
      />

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {' '}
          <a href={lead.item_url} target="_blank" rel="noopener noreferrer">
            {lead?.item_title}
          </a>
        </ModalHeader>
        <ModalPage leadDetails={lead} />
      </Modal>
    </>
  );
};

UserLeadTable.propTypes = {
  headers: PropTypes.node,
  usersData: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string,
      name: PropTypes.string,
      date: PropTypes.date,
    }),
  ),
};

UserLeadTable.defaultProps = {
  headers: [],
  usersData: [],
};

export default UserLeadTable;
