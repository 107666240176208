import React from 'react';
import { Table } from 'reactstrap';
import ButtonMailto from '../components/shared/ButtonMailto';

const ModalPage = props => {
  return (
    <>
      <div style={{ padding: '30px', marginBottom: '-30px ' }}>
        <Table>
          <thead>
            <img
              src={
                props.leadDetails.thumbnail_url !==
                '/thumbnailimagenotfound.jpg'
                  ? props.leadDetails.thumbnail_url
                  : 'https://s3.amazonaws.com/equipment.ceg/noimage2.png'
              }
              style={{
                width: '100%',
                marginLeft: '100px',
                marginTop: '-15px',
                marginBottom: '10px',
              }}
              alt="Lead Details"
            />
          </thead>
          <tbody key={1}>
            <tr>
              <td>Title</td>

              <td>
                {' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={props.leadDetails.item_url}
                >
                  {props.leadDetails?.item_title}
                </a>
              </td>
            </tr>
            <tr>
              <td>Name</td>
              <td>{props.leadDetails?.name}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>
                <ButtonMailto
                  mailto={'mailto:' + props.leadDetails?.email}
                  label={props.leadDetails?.email}
                />
              </td>
            </tr>
            <tr>
              <td>Number</td>
              <td>+ {props.leadDetails?.buyer_phone}</td>
            </tr>
            <tr>
              <td>Address</td>
              <td>{props.leadDetails?.buyer_address}</td>
            </tr>
            <tr>
              <td>City</td>
              <td>{props.leadDetails?.buyer_city}</td>
            </tr>
            <tr>
              <td>state</td>
              <td>{props.leadDetails?.buyer_state}</td>
            </tr>
            <tr>
              <td>Country</td>
              <td>{props.leadDetails?.buyer_country}</td>
            </tr>
            <tr>
              <td>BuyerType</td>
              <td>{props.leadDetails?.buyer_type}</td>
            </tr>
            <tr>
              <td>Langauge</td>
              <td>{props.leadDetails?.language_preference}</td>
            </tr>
            <tr>
              <td>Budget</td>
              <td>{props.leadDetails?.budget}</td>
            </tr>
            <tr>
              <td>Message</td>
              <td>{props.leadDetails?.message}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default ModalPage;
