import React from 'react';
import { Table, Input, Button } from 'reactstrap';
import Avatar from './Avatar';
import { addLeadToDealer } from '../apis/pages/admin';
import Pagination from './Pagination';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AdminLeadTable = ({
  headers,
  usersData,
  dealer,
  handleChange,
  handlePageClick,
  pageCount,
  onCheckBoxChange,
  leadDealerList,
  dealerId,
  ...restProps
}) => {
  const handleAddLead = async () => {
    const response = await addLeadToDealer(dealerId, leadDealerList);
    if (response?.success) {
      toast.success(response.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } else {
      toast.error(response.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Table responsive hover {...restProps}>
        <thead>
          <tr className="text-capitalize align-middle text-center">
            {headers.map((item, index) => (
              <th key={index}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {usersData?.length > 0 &&
            usersData?.map((x, index) => (
              <tr key={index}>
                <td className="align-middle text-center">
                  <Input
                    style={{ position: 'inherit', margin: 'auto' }}
                    type="checkbox"
                    value={x.lead_id}
                    checked={leadDealerList.includes(x.lead_id)}
                    onChange={() => onCheckBoxChange(x.lead_id)}
                  />
                </td>
                <td className="align-middle text-center">
                  <Avatar src={x.thumbnail_url} />
                </td>
                <td className="align-middle text-center">{x.item_title}</td>

                <td className="align-middle text-center">{x.name}</td>
                <td className="align-middle text-center">{x.email}</td>
                <td className="align-middle text-center">{x.buyer_phone}</td>
                <td className="align-middle text-center">{x.buyer_city}</td>
                <td className="align-middle text-center">{x.buyer_state}</td>
                <td className="align-middle text-center">{x.buyer_country}</td>
              </tr>
            ))}
        </tbody>
      </Table>
      <Button
        className="d-block mr-0 ml-auto mb-2"
        onClick={() => handleAddLead()}
      >
        Add Lead
      </Button>
      <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
    </>
  );
};

// AdminLeadTable.propTypes = {
//   headers: PropTypes.node,
//   usersData: PropTypes.arrayOf(
//     PropTypes.shape({
//       avatar: PropTypes.string,
//       name: PropTypes.string,
//       date: PropTypes.date,
//     }),
//   ),
// };

AdminLeadTable.defaultProps = {
  headers: [],
  usersData: [],
};

export default AdminLeadTable;
