const isSupported = global.window && !!window.localStorage;

export const setStorage = (key, value) => {
  try {
    const isValidKey = typeof key === 'string';
    const validValue =
      typeof value === 'object' && value !== null
        ? JSON.stringify(value)
        : value;
    if (isSupported && isValidKey) {
      localStorage.setItem(key, validValue);
    }
  } catch (e) {
    if (e.name === 'NS_ERROR_FILE_CORRUPTED') {
      console.warn(
        "Sorry, it looks like your browser storage has been corrupted. Please clear your storage by going to Tools -> Clear Recent History -> Cookies and set time range to 'Everything'. This will remove the corrupted browser storage across all sites.",
      );
    }
  }
};

export const getStorage = key => {
  try {
    const isValidKey = typeof key === 'string';
    if (isSupported && isValidKey) {
      return localStorage.getItem(key);
    }
  } catch (e) {
    if (e.name === 'NS_ERROR_FILE_CORRUPTED') {
      console.warn(
        "Sorry, it looks like your browser storage has been corrupted. Please clear your storage by going to Tools -> Clear Recent History -> Cookies and set time range to 'Everything'. This will remove the corrupted browser storage across all sites.",
      );
    }
  }
};
export const removeStorage = key => {
  if (isSupported && getStorage(key)) {
    localStorage.removeItem(key);
  }
};
