import React, { useState, useEffect } from 'react';
import Page from '../components/Page';
import { Card, CardBody, Col, Row } from 'reactstrap';
import UserLeadTable from '../components/UserLeadTable';
import { getLeadData } from '../apis/pages/Lead';
import { useDispatch, useSelector } from 'react-redux';
import AuthService from '../services/auth.service';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { MainLayout } from '../components/Layout';

const CardPage = () => {
  const [list, setList] = useState([]);
  const searchListState = useSelector(state => state.searchReducer);
  const [pageCount, setpageCount] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const limit = 8;
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const page = urlParams.get('page');

  var currentUser = AuthService.getCurrentUser();
  if (!currentUser) {
    Navigate({ to: '/login', state: { authState: 'LOGIN' } });
  }
  const dispatch = useDispatch();

  useEffect(() => {
    async function getLead() {
      var searchList = await searchListState;

      if (searchList?.leads?.leads?.items?.length === undefined) {
        var data = { page: Number(page) || 1 };
        if (parseInt(Number(page), 10) !== 1) {
          setSelectedIndex(parseInt(Number(page), 10) || 0);
        }
        setSelectedIndex(parseInt(Number(page), 10) - 1 || 0);
        var response = await getLeadData(data);
        setpageCount(Math.ceil(response?.leads?.count / limit));
        setList(response?.leads?.items);
      } else {
        setpageCount(Math.ceil(searchList?.leads?.leads?.count / limit));
        setList(searchList?.leads?.leads?.items);
      }
    }
    getLead();
  }, [searchListState, limit, dispatch, page]);

  const handlePageClick = async data => {
    var searchList = await searchListState;
    navigate({ path: '/', search: `?page=${data.selected + 1}` });

    setSelectedIndex(data.selected);
    let currentPage = data.selected + 1;
    var body = {
      keyword: searchList?.leads?.keyword,
      page: currentPage,
      sortBy: searchList?.leads?.sortBy,
    };
    const commentsFormServer = await getLeadData(body);
    setList(commentsFormServer?.leads?.items);
  };

  return (
    <MainLayout>
      <Page title="Lead">
        <Row>
          <Col md={12} sm={6} xs={12} className="mb-3">
            <Card>
              <CardBody>
                <UserLeadTable
                  headers={[
                    'sr',
                    'Image',
                    'Product',
                    'Name',
                    'Email',
                    'Contact',
                    'City',
                    'State',
                    'Country',
                    'Date',
                    'View',
                  ]}
                  usersData={list}
                  pageCount={pageCount}
                  handlePageClick={handlePageClick}
                  selectedIndex={selectedIndex}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    </MainLayout>
  );
};

export default CardPage;
