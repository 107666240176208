import React from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap';

const Dropdown = ({
  handleChange,
  dealer,
  dropDownKey,
  dropDownName,
}) => {
  return (
    <div style={{ textAlign: 'end', padding: '10px' }}>
      <UncontrolledButtonDropdown>
        <DropdownToggle caret size="lg">
          {dropDownName}
        </DropdownToggle>
        <DropdownMenu>
          {dealer?.length > 0 &&
            dealer?.map((x, index) => (
              <DropdownItem key={index} onClick={e => handleChange(x.id)}>
                {x[dropDownKey]}
              </DropdownItem>
            ))}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </div>
  );
};

export default Dropdown;
