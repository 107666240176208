import React, { useEffect, useState } from 'react';
import { MainLayout } from './Layout';
import '../styles/components/style.css';
import { Button, Input, Label } from 'reactstrap';
import { getUseProfileData, updateUseProfile } from '../apis/pages/user';
import authService from '../services/auth.service';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Profile = () => {
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');

  useEffect(() => {
    async function getProfileData() {
      const response = await getUseProfileData();
      setName(response.data.name);
      setLastName(response.data.lastName);
      setEmail(response.data.email);
      setAddress(response.data.address);
      setPhone(response.data.phone);
      setCompanyName(response.data.companyName);
      setCity(response.data.city);
      setState(response.data.state);
      setCountry(response.data.country);
    }
    getProfileData();
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    const user = {
      name,
      lastName,
      address,
      companyName,
      city,
      state,
      country,
      email,
      phone,
    };
    try {
      const response = await updateUseProfile(user);
      if (response.success) {
        toast.success('Profile Update Successfully!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(response.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <MainLayout>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div class="container rounded bg-white mt-2 mb-2">
        <div class="row">
          <div class="col-md-4 border-right">
            <div class="d-flex flex-column align-items-center text-center p-3 py-5">
              <img
                class="rounded-circle mt-5"
                width="150px"
                alt="Profile Pic"
                src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-jpg"
              />
              <span class="font-weight-bold">
                {authService.getCurrentUser()?.name}
              </span>
              <span class="text-black-50">
                {authService.getCurrentUser()?.email}
              </span>
              <span> </span>
            </div>
          </div>
          <div class="col-md-7 border-right">
            <div class="p-3 py-5">
              <div class="d-flex justify-content-between align-items-center mb-3">
                <h4 class="text-right">User Profile</h4>
              </div>

              <>
                <div class="row mt-2">
                  <div class="col-md-6">
                    <Label class="Labels">Name</Label>
                    <Input
                      type="text"
                      placeholder="first name"
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                  </div>
                  <div class="col-md-6">
                    <Label class="Labels">Lastname</Label>
                    <Input
                      type="text"
                      value={lastName}
                      placeholder="lastname"
                      onChange={e => setLastName(e.target.value)}
                    />
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-12">
                    <Label class="Labels">Mobile Number</Label>
                    <Input
                      type="text"
                      placeholder="Phone Number"
                      value={phone}
                      readOnly={true}
                    />
                  </div>
                  <div class="col-md-12">
                    <Label class="Labels">Email ID</Label>
                    <Input
                      type="email"
                      placeholder=" email id"
                      value={email}
                      readOnly={true}
                    />
                  </div>
                  <div class="col-md-12">
                    <Label class="Labels">Address </Label>
                    <Input
                      type="text"
                      placeholder="address "
                      value={address}
                      onChange={e => setAddress(e.target.value)}
                    />
                  </div>
                  <div class="col-md-12">
                    <Label class="Labels">Company Name </Label>
                    <Input
                      type="text"
                      placeholder="companyName "
                      value={companyName}
                      onChange={e => setCompanyName(e.target.value)}
                    />
                  </div>

                  <div class="col-md-12">
                    <Label class="Labels">City</Label>
                    <Input
                      type="text"
                      placeholder=" City"
                      value={city}
                      onChange={e => setCity(e.target.value)}
                    />
                  </div>
                  <div class="col-md-12">
                    <Label class="Labels">State</Label>
                    <Input
                      type="text"
                      placeholder=" state"
                      value={state}
                      onChange={e => setState(e.target.value)}
                    />
                  </div>
                  <div class="col-md-12">
                    <Label class="Labels">Country</Label>
                    <Input
                      type="text"
                      placeholder=" country"
                      value={country}
                      onChange={e => setCountry(e.target.value)}
                    />
                  </div>
                </div>
              </>

              <div class="mt-5 text-center">
                <Button
                  class="btn btn-primary profile-button"
                  type="button"
                  onClick={handleSubmit}
                >
                  Save Profile
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Profile;
