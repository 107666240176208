import AuthForm, { STATE_LOGIN, STATE_SIGNUP } from '../components/AuthForm';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';

const AuthPage = props => {
  const navigate = useNavigate();

  const handleAuthState = authState => {
    if (authState === STATE_LOGIN) {
      navigate('/login');
    }
    if (authState === STATE_SIGNUP) {
      navigate('/signup');
    }
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <Row
      style={{
        height: '850px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Col md={6} lg={4}>
        <Card body>
          <AuthForm
            authState={props.authState}
            onChangeAuthState={handleAuthState}
            onLogoClick={handleLogoClick}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default AuthPage;
