import PropTypes from 'prop-types';
import React from 'react';
import { Alert, Button, Form, FormGroup, Input, Label } from 'reactstrap';
import {
  loginUser,
  registerUser,
  resendEmailVerification,
} from '../apis/pages/auth';
import { Navigate } from 'react-router-dom';
import { setStorage } from '../helper/localStorage';

class AuthForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      errors: {},
      redirect: false,
      alertVisible: false,
      alertMessage: '',
      alertColor: '',
      agreeTerms: false,
      agreeTermsError: '',
      isAccountActive: true,
    };
  }
  get isLogin() {
    return this.props.authState === STATE_LOGIN;
  }

  get isSignup() {
    return this.props.authState === STATE_SIGNUP;
  }

  changeAuthState = authState => event => {
    event.preventDefault();

    this.props.onChangeAuthState(authState);
  };

  handleSubmit = async event => {
    event.preventDefault();
    if (!this.state.agreeTerms) {
      this.setState({
        agreeTermsError: 'You must Agree terms & conditions before Signup',
      });
    }
    if (this.state.firstName === '') {
      this.setState({
        name_error: (this.state.errors.firstName = 'Name is Required'),
      });
    }
    if (this.state.lastName === '') {
      this.setState({
        lastname_error: (this.state.errors.lastName = 'Name is Required'),
      });
    }
    if (this.state.email === '') {
      this.setState({
        message: (this.state.errors.email = 'Email is Required'),
      });
    } else if (
      typeof this.state !== 'undefined' &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)
    ) {
      this.setState({
        message: (this.state.errors.email = 'Invalid email address'),
      });
    }
    if (this.state.password === '') {
      this.setState({
        password_message: (this.state.errors.password = 'Password is Required'),
      });
    } else if (this.props.authState === STATE_LOGIN) {
      const bodyData = {
        email: this.state.email,
        password: this.state.password,
      };
      loginUser(bodyData).then(
        res => {
          if (!res.success) {
            this.setState(
              {
                alertMessage: res.response.data.message,
                alertVisible: !this.state.alertVisible,
                alertColor: 'danger',
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ alertVisible: !this.state.alertVisible });
                }, 2000);
              },
            );
          } else {
            if (res.status !== 'Active') {
              this.setState(
                {
                  alertMessage: 'Please Verified Your Email',
                  alertVisible: !this.state.alertVisible,
                  alertColor: 'warning',
                  isAccountActive: false,
                },
                () => {
                  window.setTimeout(() => {
                    this.setState({ alertVisible: !this.state.alertVisible });
                  }, 2000);
                },
              );
            } else {
              this.setState({ redirect: true });
            }
          }
          const userDetails = {
            user: {
              email: res.email || 'Null',
              accessToken: res.accessToken || 'Null',
              name: res.name || 'Null',
            },
          };
          setStorage('user', userDetails.user);
        },
        error => {
          console.log(error, !this.state.alertVisible);
          this.setState(
            {
              alertMessage: error.response.data.message,
              alertVisible: !this.state.alertVisible,
              alertColor: 'danger',
            },
            () => {
              window.setTimeout(() => {
                this.setState({ alertVisible: !this.state.alertVisible });
              }, 2000);
            },
          );
        },
      );
    }
    if (this.state.confirmPassword === '') {
      this.setState({
        cpassword_message: (this.state.errors.confirmPassword =
          'ConfirmPassword is Required'),
      });
    } else if (this.state.confirmPassword !== this.state.password) {
      this.setState({
        cpassword_message: (this.state.errors.confirmPassword =
          'Confirm Password did not match'),
      });
    } else if (!this.state.agreeTerms) {
      this.setState({
        agreeTermsError: 'You must Agree terms & conditions before Signup',
      });
    } else {
      const body = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        password: this.state.password,
        vendorType: 'Dealer',
      };
      const response = await registerUser(body);
      if (!response.success) {
        this.setState(
          {
            alertMessage: response.message,
            alertVisible: !this.state.alertVisible,
            alertColor: 'danger',
          },
          () => {
            window.setTimeout(() => {
              this.setState({ alertVisible: !this.state.alertVisible });
            }, 2000);
          },
        );
      } else {
        const userDetails = {
          user: {
            email: response.email || 'Null',
            accessToken: response.accessToken || 'Null',
            name: response.name || 'Null',
          },
        };
        setStorage('user', userDetails.user);
        this.setState({ redirect: true });
      }
    }
    return this.state.errors;
  };

  renderButtonText() {
    const { buttonText } = this.props;

    if (!buttonText && this.isLogin) {
      return 'Login';
    }

    if (!buttonText && this.isSignup) {
      return 'Signup';
    }

    return buttonText;
  }

  resendEmailVerificationHandler = async () => {
    var response = resendEmailVerification({
      email: this.state.email,
    });
    response
      .then(response => {
        this.setState(
          {
            alertMessage: response?.message,
            alertVisible: !this.state.alertVisible,
            alertColor: response?.success ? 'success' : 'danger',
          },
          () => {
            window.setTimeout(() => {
              this.setState({ alertVisible: !this.state.alertVisible });
            }, 2500);
          },
        );
      })
      .catch(error => {
        this.setState(
          {
            alertMessage: error.response.data.message,
            alertVisible: !this.state.alertVisible,
            alertColor: 'danger',
          },
          () => {
            window.setTimeout(() => {
              this.setState({ alertVisible: !this.state.alertVisible });
            }, 2000);
          },
        );
      });
  };
  render() {
    const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      children,
      onLogoClick,
      firstNameLabel,
      lastNameLabel,
      lastNameInputProps,
      firstNameInputProps,
    } = this.props;
    if (this.state.redirect) {
      return <Navigate to="/" replace={true} />;
    }

    return (
      <>
        {this.state.alertVisible && (
          <Alert color={this.state.alertColor} isOpen={this.state.visible}>
            {this.state.alertMessage}
          </Alert>
        )}

        <Form onSubmit={this.handleSubmit}>
          {showLogo && (
            <div className="text-center pb-4">
              <img
                src="https://d3g7ucapfhrqqt.cloudfront.net/images/ironlist-logo.png"
                className="rounded"
                style={{ width: '40%', height: '60%', cursor: 'pointer' }}
                alt="logo"
                onClick={onLogoClick}
              />
            </div>
          )}
          <FormGroup>
            <Label for={usernameLabel}>{usernameLabel}</Label>
            <Input
              {...usernameInputProps}
              onChange={e => {
                this.setState({ email: e.target.value });
                if (e.target.value !== '') {
                  this.setState({ message: '' });
                }
              }}
            />
            <span style={{ color: 'red' }}>{this.state.message}</span>
          </FormGroup>

          <FormGroup>
            <Label for={passwordLabel}>{passwordLabel}</Label>
            <Input
              {...passwordInputProps}
              onChange={e => {
                this.setState({ password: e.target.value });
                if (e.target.value !== '') {
                  this.setState({ password_message: '' });
                }
              }}
            />
            <span style={{ color: 'red' }}>{this.state.password_message}</span>
          </FormGroup>

          {this.isLogin & !this.state.isAccountActive ? (
            <>
              <h6
                style={{
                  color: 'rgb(248, 137, 50)',
                  fontWeight: ' bold',
                  cursor: 'pointer',
                }}
                onClick={() => this.resendEmailVerificationHandler()}
              >
                Resend Email Verification Link
              </h6>
            </>
          ) : (
            <></>
          )}
          {this.isSignup && (
            <>
              <FormGroup>
                <Label for={confirmPasswordLabel}>{confirmPasswordLabel}</Label>
                <Input
                  {...confirmPasswordInputProps}
                  onChange={e => {
                    this.setState({ confirmPassword: e.target.value });
                    if (e.target.value !== '') {
                      this.setState({ cpassword_message: '' });
                    }
                  }}
                />
                <span style={{ color: 'red' }}>
                  {this.state.cpassword_message}
                </span>
              </FormGroup>
              <FormGroup>
                <Label for={firstNameLabel}>{firstNameLabel}</Label>
                <Input
                  {...firstNameInputProps}
                  onChange={e => {
                    this.setState({ firstName: e.target.value });
                    if (e.target.value !== '') {
                      this.setState({ name_error: '' });
                    }
                  }}
                />
                <span style={{ color: 'red' }}>{this.state.name_error}</span>
              </FormGroup>
              <FormGroup>
                <Label for={lastNameLabel}>{lastNameLabel}</Label>
                <Input
                  {...lastNameInputProps}
                  onChange={e => {
                    this.setState({ lastName: e.target.value });
                    if (e.target.value !== '') {
                      this.setState({ lastname_error: '' });
                    }
                  }}
                />
                <span style={{ color: 'red' }}>
                  {this.state.lastname_error}
                </span>
              </FormGroup>
            </>
          )}
          {this.isSignup && (
            <>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    onChange={e => {
                      this.setState({
                        agreeTerms: !this.state.agreeTerms,
                      });
                      if (e.target.value !== '') {
                        this.setState({ agreeTermsError: '' });
                      }
                    }}
                  />{' '}
                  &nbsp;Agree the terms and policy
                </Label>
              </FormGroup>
              <span style={{ color: 'red' }}>{this.state.agreeTermsError}</span>
            </>
          )}
          <hr />
          <Button
            size="lg"
            className="bg-gradient-theme-left border-0"
            block
            onClick={this.handleSubmit}
          >
            {this.renderButtonText()}
          </Button>
          <div className="text-center pt-1">
            <h6>or</h6>
            <h6>
              {this.isSignup ? (
                <a href="#login" onClick={this.changeAuthState(STATE_LOGIN)}>
                  Login
                </a>
              ) : (
                <a href="#signup" onClick={this.changeAuthState(STATE_SIGNUP)}>
                  Signup
                </a>
              )}
            </h6>
          </div>
          {children}
        </Form>
      </>
    );
  }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  lastNameLabel: PropTypes.string,
  firstNameLabel: PropTypes.string,
  firstNameInputProps: PropTypes.object,
  lastNameInputProps: PropTypes.object,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,

  emailValidate: PropTypes.object,
};

AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Email',
  firstNameLabel: 'First name',
  lastNameLabel: 'Last name',
  usernameInputProps: {
    type: 'email',
    name: 'email',
    placeholder: 'your@email.com',
  },
  firstNameInputProps: {
    type: 'name',
    name: 'first_name',
    placeholder: 'First Name',
  },
  lastNameInputProps: {
    type: 'name',
    name: 'last_name',
    placeholder: 'Last Name',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    name: 'password',
    placeholder: 'your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    name: 'confirmPassword',
    placeholder: 'confirm your password',
  },
  emailValidate: { success: true, message: 'Please enter your email address' },

  onLogoClick: () => {},
};

export default AuthForm;
