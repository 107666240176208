import React from 'react';
import './styles/reduction.scss';
import AuthPage from './page/AuthPage';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { STATE_LOGIN, STATE_SIGNUP } from './components/AuthForm';
import UserList from './page/UserList';
import { Provider } from 'react-redux';
import store from './redux/stores';
import EmailVerification from './page/EmailVerification';
import Profile from './components/Profile';
import AdminPage from './page/AdminPage';

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<UserList />}></Route>
          <Route exact path="/profile" element={<Profile />}></Route>
          <Route exact path="/leads" element={<AdminPage />}></Route>
          <Route
            exact
            path="/login"
            element={<AuthPage authState={STATE_LOGIN} />}
          ></Route>
          <Route
            exact
            path="/signup"
            element={<AuthPage authState={STATE_SIGNUP} />}
          ></Route>
          <Route
            exact
            path="/email-verification/:confirmationToken"
            element={<EmailVerification />}
          ></Route>
          <Route path="*" element={<UserList />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
